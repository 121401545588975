// src/App.js
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResetPassword from "./screens/ResetPassword";
import { ThemeProvider } from "@mui/material/styles";
import VerifyEmail from "./screens/VerifyEmail";
import Home from "./screens/Home";
import { theme } from "./styles/globalStyle";
import ResponsiveAppBar from "./components/ResponsiveNavbar";
import AboutUs from "./screens/AboutUs";
import Blog from "./screens/Blog";
import Contact from "./screens/Contact";
import BlogDetails from "./screens/BlogDetails";
import Download from "./screens/Download";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ResponsiveAppBar />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/download" element={<Download />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
