import React from "react";
import styled from "styled-components";
import pic1 from "../assets/pictures/pic1.png";
import { useNavigate } from "react-router-dom";

function HomeSection2() {
  const navigate = useNavigate();
  return (
    <SectionContainer id="aboutUs">
      <SectionWrapper>
        <SectionContent>
          <SectionTextColumn>
            <SectionTextWrapper>
              <SectionTitle>
                Une solution conçue pour vous simplifier la vie
              </SectionTitle>
              <SectionDescription>
                Votre compagnon de route pour des amendes sans surprises
                <br />
                Chez GérerMonPermis, nous comprenons que les amendes peuvent
                être une source de stress et de tracas. C'est pourquoi nous
                avons créé une application simple et efficace pour vous aider à
                gérer vos amendes en toute sérénité.
              </SectionDescription>
              <SectionButtonsWrapper>
                <LearnMoreButton
                  onClick={() => {
                    navigate("/aboutUs");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  En savoir plus
                </LearnMoreButton>
              </SectionButtonsWrapper>
            </SectionTextWrapper>
          </SectionTextColumn>
          <SectionImageColumn>
            <SectionImage src={pic1} alt="Section image" />
          </SectionImageColumn>
        </SectionContent>
      </SectionWrapper>
    </SectionContainer>
  );
}

export default HomeSection2;

const SectionContainer = styled.section`
  align-items: center;
  background-color: var(--Primary-50, #e8f1fc);
  display: flex;
  justify-content: center;
  padding: 80px 60px;
  margin-top: 180px;
  @media (max-width: 780px) {
    margin-top: 40px;
    padding: 80px 32px;
  }
`;

const SectionWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  max-width: 1090px;

  @media (max-width: 780px) {
    //  max-width: 100%;
  }
`;

const SectionContent = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 780px) {
    flex-direction: column;
    /* flex-direction: column;
    align-items: stretch;
    gap: 0; */
  }
`;

const SectionTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin-left: 0;

  @media (max-width: 780px) {
    width: 92%;
  }
`;

const SectionTextWrapper = styled.div`
  justify-content: center;
  border-radius: 32px;
  background: linear-gradient(90deg, #c7def9 0%, #e8f1fc 78.26%);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  padding: 80px 24px;

  @media (max-width: 780px) {
    padding: 24px;
  }
`;

const SectionTitle = styled.h1`
  color: var(--Primary-900, #0a3567);
  letter-spacing: -0.72px;
  margin-top: 66px;
  font: 700 36px/44px Poppins, sans-serif;
  text-align: start;
  margin-bottom: 8px;
  font: 700 52px/56px Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 700 36px/44px Poppins, sans-serif;
  }
  @media (max-width: 780px) {
    font: 700 36px/44px Poppins, sans-serif;
    margin: 0;
  }
`;

const SectionDescription = styled.p`
  color: var(--Base-Black, #222426);
  text-align: start;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 28px;

  @media (max-width: 780px) {
    /* max-width: 100%; */
  }
`;

const SectionButtonsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 156%;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const LearnMoreButton = styled.button`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  border-radius: 16px;
  background-color: #1776e5;
  color: #f7f9fa;
  padding: 18px 16px;
  border: none;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const SectionImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  overflow: hidden;
  border-radius: 32px;
  margin-left: 20px;

  @media (max-width: 780px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const SectionImage = styled.img`
  aspect-ratio: 0.76;
  object-fit: cover;
  object-position: center;
  width: 100%;
  flex-grow: 1;

  @media (max-width: 991) {
    /* max-width: 100%;
    margin-top: 24px; */
  }
`;
