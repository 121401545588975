export const reviews = [
  {
    userName: "Alain Cervulle",
    role: "Utilisateur sur google play",
    comment: `Enfin, une application intuitive pour gagner du temps et en finir avec les majorations ! Merci, je peux suivre mes démarches d'un seul coup d'œil pour l'ensemble de ma flotte de véhicules.`,
  },
  {
    userName: "Abdelouahad Khelifi",
    role: "Utilisateur sur google play",
    comment: `Super application, je suis beaucoup mieux organisé maintenant et je n'oubli plus aucune amendes grâce au notifications de rappels et tout est sur l'application!`,
  },
  {
    userName: "Maëva Maëva",
    role: "Utilisateur sur google play",
    comment: `Super application, utile et rapide pour les contraventions et avoir des rappels concernant la gestion du véhicule et les contraventions`,
  },
  {
    userName: "Soussmol",
    role: "Utilisateur sur app store",
    comment: `C'est en recherchant une application pour payer mon amende que je suis tombé dessus, je dirai que c'est pas mal ça permet de gérer les contraventions et surtout d'avoir un état sur son nombre de points ainsi que les dates de restitutions des points. Il y a aussi un onglet pour enregistrer son véhicule pour les contrôles tehniques franchement c'est plutôt pas mal`,
  },
  {
    userName: "misal93",
    role: "Utilisateur sur app store",
    comment: `Je viens de tomber sur cette application à vrais dire merci plus besoin d'aller sur le site web connexion Face ID je peux consulter mes points plus facilement et avoir le contrôle sur mon permis merci au développeur vous êtes les meilleurs`,
  },
  {
    userName: "Raphic.Z",
    role: "Utilisateur sur app store",
    comment: `Jai scanner l'amande et tout était déjà prêt rempli je peu contester ,payer rapidement et en bonus je peu aussi conserver mon amande dans l application !`,
  },
];
