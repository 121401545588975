import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { DownloadSimple, X } from "@phosphor-icons/react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "../assets/icons/websiteLogo.svg";
import { Fontsize, palette, poppins, roboto } from "../styles/globalStyle";

const pages = [
  { label: "Accueil", path: "/" },
  { label: "A propos de nous", path: "/aboutUs" },
  { label: "Blog", path: "/blog" },
  { label: "Contact", path: "/contact" },
];

function ResponsiveAppBar() {
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setIsDrawerOpened(event.currentTarget);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpened(open);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      style={{
        boxShadow: "none",
        paddingTop: "24px",
        paddingBottom: "24px",
        backgroundColor: "#e8f1fc",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              mr: 1,
              alignItems: "center",
            }}
          >
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt="website logo svg"
              style={{ width: "60px", height: "60px" }}
            />
            <Typography
              onClick={() => navigate("/")}
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                marginLeft: 1,
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: roboto,
                fontWeight: 500,
                fontSize: 16,
                color: palette.Primary[500],
                textDecoration: "none",
              }}
            >
              GérerMonPermis
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon
                style={{ color: palette.Primary[500], width: 32, height: 32 }}
              />
            </IconButton>
            <Drawer
              anchor="right"
              open={isDrawerOpened}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{
                  marginTop: 15,
                  width: 270,
                }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <X
                  style={{
                    paddingRight: 8,
                    alignSelf: "end",
                    position: "absolute",
                    right: 12,
                    top: 8,
                  }}
                  color={palette.Primary[500]}
                  weight="bold"
                  size={32}
                />
                <List>
                  {pages.map((page, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton>
                        <ListItemText
                          style={{
                            color: palette.Base.Black,
                            fontFamily: roboto,
                            fontWeight: 500,
                            fontSize: 19,
                          }}
                          primary={page.label}
                          onClick={() => {
                            navigate(page.path);
                            console.log("list");
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <NavLink
                key={page}
                to={page.path}
                activeStyle={{ color: "red" }}
                style={({ isActive, isPending, isTransitioning }) => {
                  return {
                    marginLeft: "24px",
                    marginRight: "24px",
                    textDecoration: "none",
                    color: isActive ? palette.Primary[600] : palette.Base.Black,
                    fontFamily: roboto,
                    fontSize: 18,
                    fontWeight: 500,
                    backgroundColor: "transparent",
                  };
                }}
              >
                {page.label}
              </NavLink>
            ))}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
const StyledButton = styled.button`
  background-color: #1776e5;
  color: #fff;
  font-weight: 500;
  font-family: "Roboto";
  font-size: 18px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(23, 118, 229, 0.28);
  cursor: pointer;
`;
