import React from "react";
import styled from "styled-components";
import bgShape from "../assets/bgShapeQr.svg";
import FAndQSection from "../containers/FAndQSection";
import QrCodeSection from "../containers/QrCodeSection";
import FooterSection from "../containers/FooterSection";
import ContactUsSection from "../containers/ContactUsSection";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <Wrapper>
      <Helmet>
        <title>Contactez-nous - Gérer Mon Permis</title>
        <meta
          name="description"
          content="Besoin d'aide ou d'informations supplémentaires ? Contactez-nous via cette page pour toute question concernant l'application Gérer Mon Permis."
        />
        <meta
          name="keywords"
          content="Application mobile, permis en france, Gérer Mon Permis, suivi des points de permis, vérification des points de permis, consultation des points de permis, gestion des infractions de conduite, récupération des points de permis, renouvellement du permis de conduire, suspension du permis de conduire, permis de conduire en ligne, historique des infractions de conduite, alerte de perte de points, paiement des amendes, amendes de stationnement, amendes pour excès de vitesse, gestion des contraventions, contestation d'amende, consultation des amendes, suivi des amendes, paiement en ligne des amendes, OCR des amendes, scanner des amendes, rappel entretien véhicule, gestion de l'entretien du véhicule, rappel vidange voiture, rappel contrôle technique, gestion des documents du véhicule, historique d'entretien du véhicule, suivi de l'entretien automobile, planification des entretiens de voiture, gestion de flotte de véhicules, alerte maintenance voiture, application de gestion de permis, application de gestion de véhicules, application de suivi des amendes, sécurité routière, code de la route, conseils de conduite, gestion des infractions, gestion des documents de véhicule, application mobile de gestion de permis, solution digitale pour conducteurs"
        />
      </Helmet>
      <ContactUsSection />
      <FAndQSection />
      <QrCodeSection bgShape={bgShape} />
      <FooterSection />
    </Wrapper>
  );
}

export default Contact;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
