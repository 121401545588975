import React from "react";
import styled from "styled-components";

function TextArea(props) {
  return <Area {...props} />;
}

export default TextArea;

const Area = styled.textarea`
  display: flex;
  height: 200px;
  padding: 16px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 2px solid #1776e5;
  color: #1776e5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 10px;
`;
