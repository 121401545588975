import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import shape500 from "../assets/shape500.svg";
import shape50 from "../assets/shape50.svg";
import styled from "styled-components";
import QrCodeSection from "../containers/QrCodeSection";
import bgShape from "../assets/bgShapeQr.svg";
import FooterSection from "../containers/FooterSection";
import { Check } from "@phosphor-icons/react";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  console.log(`Token reçu : ${token}`);
  const [message, setMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(
          `/users/verify-email`,
          JSON.stringify({ token }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Response status:", response.status); // Ajout du log de la réponse
        console.log("Response data:", response.data);
        if (response.status === 200) {
          setMessage("Email vérifié avec succès.");
        } else {
          setMessage("Erreur lors de la vérification de l'email.");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Erreur lors de la vérification de l'email:", error);
        setMessage("Erreur lors de la vérification de l'email.");
        setIsLoading(false);
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token, isLoading]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <Wrapper>
      <SectionWrapper>
        {!isMobile && (
          <>
            <Shape500 src={shape500} />
            <Shape50 src={shape50} />
          </>
        )}
        {!isLoading && message === "Email vérifié avec succès." && (
          <Check
            style={{
              borderRadius: 160,
              padding: "8px",
              aspectRatio: 1,
              backgroundColor: " #16A34A",
            }}
            color="#f7f9fa"
            weight="bold"
            size={48}
          />
        )}
        {!isLoading && (
          <Title>
            {message === "Email vérifié avec succès."
              ? "Votre email a été vérifié Vous pouvez continuer à utiliser l'application."
              : "Erreur lors de la vérification de l'email"}
          </Title>
        )}
      </SectionWrapper>
      <QrCodeSection bgShape={bgShape} />
      <FooterSection />
    </Wrapper>
  );
};

export default VerifyEmail;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 0 164px;
  width: 100%;
  gap: 16px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const Title = styled.p`
  color: #16a34a;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;
const Desc = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Button = styled.button`
  border-radius: 16px;
  background: #1776e5;
  display: flex;
  height: 48px;

  width: auto;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #f7f9fa;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 60%;
  @media (max-width: 780px) {
    width: 95%;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
  margin: 0 16px;
  text-align: start;
`;

const Shape500 = styled.img`
  position: absolute;
  right: 0;
  width: 15%;
  bottom: -120px;
`;
const Shape50 = styled.img`
  position: absolute;
  width: 15%;
  left: 0;
  top: 0;
  transform: scaleX(-1);
`;
