import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import QrCodeSection from "../containers/QrCodeSection";
import bgShape from "../assets/bgShapeQr.svg";
import FooterSection from "../containers/FooterSection";
import { Formik } from "formik";
import InputText from "../components/InputText";

import shape500 from "../assets/shape500.svg";
import shape50 from "../assets/shape50.svg";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [message, setMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const handleSubmit = async (values) => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const response = await axios.post("/users/password-change", {
        token,
        newPassword: values.password,
      });
      if (response.status === 200) {
        setMessage("Mot de passe réinitialisé avec succès.");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la réinitialisation du mot de passe:",
        error
      );
      setMessage("Erreur lors de la réinitialisation du mot de passe.");
    }
  };

  return (
    <Wrapper>
      <SectionWrapper>
        {!isMobile && (
          <>
            <Shape500 src={shape500} />
            <Shape50 src={shape50} />
          </>
        )}
        <Title>Réinitialiser le mot de passe</Title>
        <Desc>Saisissez le nouveau mot de passe </Desc>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          onSubmit={(values) => handleSubmit(values)}
          validate={(values) => {
            const errors = {};
            if (!values.password) {
              errors.password = "Required";
            }
            if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Les mots de passe ne correspondent pas.";
            }
            return errors;
          }}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <InputText
                type="password"
                placeholder="Nouveau mot de passe"
                name="password"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.password}
              />
              <ErrorTxt>
                {formikProps.errors.password &&
                  formikProps.touched.password &&
                  formikProps.errors.password}
              </ErrorTxt>
              <InputText
                type="password"
                name="confirmPassword"
                placeholder="Confirmer le nouveau mot de passe"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.confirmPassword}
              />
              <ErrorTxt>
                {formikProps.errors.confirmPassword &&
                  formikProps.touched.confirmPassword &&
                  formikProps.errors.confirmPassword}
              </ErrorTxt>
              <Button type="submit" disabled={formikProps.isSubmitting}>
                Réinitialiser
              </Button>
              <Message>{message}</Message>
            </Form>
          )}
        </Formik>
      </SectionWrapper>
      <QrCodeSection bgShape={bgShape} />
      <FooterSection />
    </Wrapper>
  );
};

export default ResetPassword;

const Message = styled.p`
  color: #28a745; // Change as per your color theme
  font-size: 16px;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 0 164px;
  width: 100%;
  gap: 16px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;
const Desc = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Button = styled.button`
  border-radius: 16px;
  background: #1776e5;
  display: flex;
  height: 48px;

  width: auto;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #f7f9fa;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 60%;
  @media (max-width: 780px) {
    width: 95%;
  }
`;

const ErrorTxt = styled.p`
  color: red;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
  margin: 0 16px;
  text-align: start;
`;

const Shape500 = styled.img`
  position: absolute;
  right: 0;
  width: 15%;
  bottom: -120px;
`;
const Shape50 = styled.img`
  position: absolute;
  width: 15%;
  left: 0;
  top: 0;
  transform: scaleX(-1);
`;
