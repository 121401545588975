import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 780,
      md: 1150,
      lg: 1440,
      xl: 1920,
      custom: 1440,
    },
  },
});

/* palette */
export const palette = {
  Danger: {
    50: "#fef2f2",

    100: "#fee2e2",

    200: "#fecaca",

    300: "#fca5a5",

    400: "#f87171",

    500: "#ef4444",

    600: "#dc2626",

    700: "#b91c1c",

    800: "#991b1b",

    900: "#7f1d1d",
  },
  Warning: {
    50: "#fffbeb",

    100: "#fef3c7",

    200: "#fde68a",

    300: "#fcd34d",

    400: "#fbbf24",

    500: "#f59e0b",

    600: "#d97706",

    700: "#b45309",

    800: "#92400e",

    900: "#78350f",
  },
  Success: {
    50: "#f0fdf4",

    100: "#dcfce7",

    200: "#bbf7d0",

    300: "#86efac",

    400: "#4ade80",

    500: "#22c55e",

    600: "#16a34a",

    700: "#15803d",

    800: "#166534",

    900: "#14532d",
  },
  Nature: {
    50: "#fdfdfd",

    100: "#f6f6f6",

    200: "#f2f2f2",

    300: "#dedede",

    400: "#c8c8c9",

    500: "#9c9c9d",

    600: "#6f7172",

    700: "#5e5f61",

    800: "#5e5f61",

    900: "#434547",
  },
  Primary: {
    50: "#e8f1fc",

    100: "#c7def9",

    200: "#9bc4f4",

    300: "#6da9ef",

    400: "#418fea",

    500: "#1776e5",

    600: "#1464c3",

    700: "#1054a3",

    800: "#0d4383",

    900: "#0a3567",
  },
  Base: {
    darkgray: "#505459",

    lightgry: "#c1c4c7",

    Black: "#222426",

    White: "#f7f9fa",
  },
};

/* fonts */
export const poppins = "Poppins";
export const roboto = "Roboto";

/* Style Variables */
export const Spacing = {
  baseSpace1: 8,
  baseSpace2: 16,
  baseSpace4: 32,
  baseSpace14: 112,
};
/* Style Variables */
export const Fontsize = {
  displayLarge: 52,
  displaySmall: 44,
  h1Desktop: 40,
  h1Mobile: 36,
  h2Desktop: 36,
  h2Mobile: 32,
  h3Desktop: 32,
  h3Mobile: 28,
  h4Desktop: 28,
  h4Mobile: 24,
  h5Desktop: 24,
  h5Mobile: 20,
  h6Desktop: 20,
  h6Mobile: 18,
  paragraphL: 18,
  paragraphM: 16,
  paragraphS: 14,
  paragraphXS: 12,
  paragraphOverLine: 14,
  labelL: 16,
  labelM: 14,
  labelS: 12,
  labelXS: 10,
};
