import React, { useState } from "react";
import styled from "styled-components";
import shape50 from "../assets/shape50.svg";
import picture from "../assets/pictures/picF&Q.webp";
import { X, Plus } from "@phosphor-icons/react";
import { palette } from "../styles/globalStyle";

function FAndQSection() {
  const [selected, setSelected] = useState(1);
  const data = [
    {
      id: 1,
      selected: selected === 1,
      q: "Est-ce que mes données personnelles sont sécurisées avec GérerMonPermis ?",
      r: "Nous comprenons l'importance de la sécurité et de la confidentialité de vos informations. Rassurez-vous, nous ne stockons pas les informations sensibles telles que les identifiants de connexion au site permisdeconduire.gouv.fr. Notre application ne conserve que les informations non sensibles nécessaires à son fonctionnement, telles que votre nom, votre adresse e-mail, et d'autres détails de contact. Votre sécurité et votre confidentialité sont notre priorité absolue, et nous prenons des mesures strictes pour garantir la protection de vos données personnelles.",
    },
    {
      id: 2,
      selected: selected === 2,
      q: "Puis-je contester une amende à travers l'application ?",
      r: "Oui, vous pouvez contester une amende via l'application. Il vous suffit de sélectionner l'amende que vous souhaitez contester, puis de choisir l'option \"Contester\". Vous serez guidé à travers le processus de contestation, et vous pourrez télécharger les documents nécessaires pour appuyer votre contestation.",
    },
    {
      id: 3,
      selected: selected === 3,
      q: "Comment puis-je suivre le statut de mes amendes ?",
      r: "Vous pouvez facilement suivre le statut de vos amendes dans l'application. Une fois que vous avez ajouté une amende, vous pouvez la visualiser dans la section \"Mes Amendes\", puis selectionnez l'amende concernée et voir si elle est marquée comme payée, impayée ou contestée.",
    },
    {
      id: 4,
      selected: selected === 4,
      q: " Puis-je recevoir des rappels pour payer mes amendes ?",
      r: "Oui, vous recevrez des notifications pour payer les amendes impayées avant la date limite de majoration. Ces rappels vous permettront de rester informé des échéances et d'éviter les pénalités supplémentaires.",
    },
  ];
  return (
    <Wrapper id="F&Q">
      <BgShape src={shape50} />
      <ContentWrapper>
        <Img src={picture} />
        <RightWrapper>
          <Title>Tout savoir sur GérerMonPermis</Title>
          <Desc>
            Trouvez rapidement des réponses à vos questions les plus fréquentes
            sur GérerMonPermis.
          </Desc>
          <QuestionWrapper>
            {data.map((question, index) => (
              <>
                <QuestionHeader onClick={() => setSelected(question.id)}>
                  <Question>{question.q}</Question>
                  {question.selected ? (
                    <X color={palette.Primary[500]} weight="bold" size={32} />
                  ) : (
                    <Plus
                      color={palette.Primary[500]}
                      weight="bold"
                      size={32}
                    />
                  )}
                </QuestionHeader>
                {question.selected && <Response>{question.r}</Response>}
                <Divider />
              </>
            ))}
          </QuestionWrapper>
        </RightWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

export default FAndQSection;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 64px;
  justify-content: space-between;
  @media (max-width: 780px) {
  }
`;
const ContentWrapper = styled.div`
  position: relative;
  margin: 80px 5%;
  display: flex;
  width: 100%;
  align-items: start;

  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    // justify-content: start;
  }
`;
const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const BgShape = styled.img`
  position: absolute;
  left: 0;
  bottom: 20px;
  transform: scaleX(-1) scaleY(-1);
  @media (max-width: 780px) {
    display: none;
  }
`;
const Img = styled.img`
  width: 48%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0;
  text-align: start;
  @media (max-width: 780px) {
    margin-top: 24px;
    color: #0a3567;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.64px;
  }
`;
const Desc = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  text-align: start;
  margin-top: 8px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;
const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const Question = styled.p`
  color: #1776e5;
  margin: 0;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
  margin: 16px 0;
`;
const Response = styled.p`
  text-align: start;
  margin: 0;
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 8px;
`;

const Divider = styled.div`
  height: 3px;
  width: 100%;
  background-color: #1776e5;
`;
