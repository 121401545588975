import React, { useEffect, useState } from "react";
import styled from "styled-components";
import divider from "../assets/divider.svg";
import {
  EnvelopeOpen,
  PhoneCall,
  InstagramLogo,
  LinkedinLogo,
  FacebookLogo,
  XLogo,
} from "@phosphor-icons/react";
import { palette } from "../styles/globalStyle";
import { Formik } from "formik";
import InputText from "../components/InputText";
import TextArea from "../components/TextArea";

import axios from "axios";
import Toast from "../components/Toast";
import emailSchema from "../models/emailSchema";

function ContactUsSection() {
  const [isMobile, setIsMobile] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(null);
  const [toastVariant, setToastVariant] = useState(null);

  const contactDetails = {
    email: "contact@kiamtech.com",
    phone: "+33 9 77 19 57 61",
    adress: "La Défense, 92000 Nanterre",
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const emailData = {
      recipient: "contact@kiamtech.com",
      subject: `${values.name} - from portal gererMonPermis`,
      customer_email: values.email,
      content: values.massage,
      username: values.name,
    };
    console.log(emailData);
    try {
      const res = await axios.post(`/contact-us`, emailData);
      console.log("res :", res);
      if (res.status === 200) {
        resetForm();
        setMessageInfo("email envoyé avec succés");
        setToastVariant("success");
        setIsToastOpen(true);
      }
    } catch (error) {
      setMessageInfo("L'e-mail n'a pas été envoyé");
      setToastVariant("error");
      setIsToastOpen(true);
      console.error("erreur lors de l'envoi de l email :", error);
    }
    setSubmitting(false);
  };

  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <SectionWrapper>
      <LeftContainer>
        <ContactTitle>Contactez-nous pour plus d'informations.</ContactTitle>
        <Divider src={divider} />
        <ContactWrapper>
          <ContactIcon>
            <EnvelopeOpen
              style={{ margin: "4px 8px" }}
              color={palette.Primary[50]}
              size={isMobile ? 32 : 48}
            />
          </ContactIcon>
          <ContactTxtWrapper>
            <ContactTypeTxt>Notre Email</ContactTypeTxt>
            <ContactTxt>{contactDetails.email}</ContactTxt>
          </ContactTxtWrapper>
        </ContactWrapper>
        <ContactWrapper>
          <ContactIcon>
            <PhoneCall
              style={{ margin: "4px 8px" }}
              color={palette.Primary[50]}
              size={isMobile ? 32 : 48}
            />
          </ContactIcon>
          <ContactTxtWrapper>
            <ContactTypeTxt>Numéro de téléphone</ContactTypeTxt>
            <ContactTxt>{contactDetails.phone}</ContactTxt>
          </ContactTxtWrapper>
        </ContactWrapper>
        <Divider src={divider} />
        <SocialMedia>
          <LinkWrapper>
            <InstagramLogo
              style={{ margin: "4px 8px" }}
              color={palette.Primary[500]}
              size={56}
              onClick={() => {
                handleOpenUrl("https://www.instagram.com/gerermonpermis.app/");
              }}
            />
          </LinkWrapper>

          <LinkWrapper>
            <FacebookLogo
              style={{ margin: "4px 8px" }}
              color={palette.Primary[500]}
              size={56}
              onClick={() => {
                handleOpenUrl(
                  "https://www.facebook.com/people/G%C3%A9rermonpermis/61561998237437/"
                );
              }}
            />
          </LinkWrapper>
          <LinkWrapper>
          <XLogo
            style={{ margin: "4px 8px" }}
            color={palette.Primary[500]}
            size={56}
            onClick={() => {
              handleOpenUrl(
                "https://x.com/GererMonPermis"
              );
            }}
          />
          </LinkWrapper>
          {/* <LinkedinLogo
            style={{ margin: "4px 8px" }}
            color={palette.Primary[500]}
            size={56}
          /> */}
        </SocialMedia>
      </LeftContainer>
      <RightContainer>
        <Formik
          initialValues={{ name: "", email: "", massage: "" }}
          validationSchema={emailSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, { setSubmitting, resetForm });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputText
                maxLength={50}
                type="text"
                name="name"
                placeholder="Nom et prénom"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                style={{ borderColor: errors.name ? "red" : "#1776e5" }}
              />
              <ErrorTxt>{errors.name && touched.name && errors.name}</ErrorTxt>
              <InputText
                maxLength={70}
                type="email"
                name="email"
                placeholder="Adresse e-mail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                style={{ borderColor: errors.email ? "red" : "#1776e5" }}
              />
              <ErrorTxt>
                {errors.email && touched.email && errors.email}
              </ErrorTxt>
              <TextArea
                maxLength={1000}
                name="massage"
                placeholder="Votre message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.massage}
                style={{ borderColor: errors.massage ? "red" : "#1776e5" }}
              />
              <ErrorTxt>
                {errors.massage && touched.massage && errors.massage}
              </ErrorTxt>
              <Button
                type="submit"
                disabled={!(touched.email && isValid) || isSubmitting}
                // onClick={() => alert("cc")}
              >
                Envoyer
              </Button>
            </Form>
          )}
        </Formik>
      </RightContainer>
      <Toast
        isToastOpen={isToastOpen}
        setIsToastOpen={setIsToastOpen}
        variant={toastVariant}
        message={messageInfo}
      />
    </SectionWrapper>
  );
}

export default ContactUsSection;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  width: 90%;
  gap: 16px;
  @media (max-width: 1320px) {
    align-items: start;
  }
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: stretch;
  align-items: stretch;
  width: 50%;
  height: 100%;
  flex-shrink: 1;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const ContactTitle = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.56px;
  text-align: start;
`;

const ContactIcon = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 168px;
  background: #0a3567;
  aspect-ratio: 1;
`;
const Divider = styled.img`
  width: 100%;
`;

const ContactWrapper = styled.div`
  display: flex;
  margin: 16px 0;
  align-items: center;
`;
const ContactTxtWrapper = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 30px;
`;

const ContactTypeTxt = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin: 0;
  text-align: start;
`;
const ContactTxt = styled.p`
  color: #1776e5;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  text-align: start;
`;

const SocialMedia = styled.div`
  margin-top: 24px;
`;

const Button = styled.button`
  border-radius: 16px;
  background: #1776e5;
  display: flex;
  height: 48px;

  width: auto;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #f7f9fa;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;

  &:disabled {
    background-color: #dce1e3;
    color: #666666;
    cursor: not-allowed;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ErrorTxt = styled.p`
  color: red;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
  margin: 0 16px;
  text-align: start;
`;

const LinkWrapper = styled.button`
  background-color: transparent;
  padding: 0;
  border-radius: 12px;
  &:hover {
    background-color: #9bc4f4; // Default hover color
  }
`;
