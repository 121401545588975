import React, { useEffect } from "react";
import styled from "styled-components";
import googleplayIcon from "../assets/icons/googleplay.svg";
import appleIcon from "../assets/icons/apple.svg";
import whiteLogo from "../assets/icons/websiteLogo.svg";
import screenShot from "../assets/pictures/screenshotFine.webp";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Download() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const origin = params.get("origin");
  const currentTimestamp = new Date();

  const QrCodeData = {
    userAgent,
    origin,
    scanDate: currentTimestamp,
  };

  useEffect(() => {
    if (
      ["MacIntel", "iPad", "iPhone"].includes(navigator.platform) ||
      navigator.userAgent.includes("Mac")
    ) {
      window.location.href =
        "https://apps.apple.com/app/g%C3%A9rermonpermis/id6502949932";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.kiamtech.gerermonpermis";
    }
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Téléchargez Gérer Mon Permis</title>
        <meta
          name="description"
          content="Téléchargez l'application Gérer Mon Permis sur App store ou Google play pour vérifier vos points de permis, payer vos amendes, et gérer vos véhicules efficacement."
        />
        <meta
          name="keywords"
          content="Application mobile, permis en france, Gérer Mon Permis, suivi des points de permis, vérification des points de permis, consultation des points de permis, gestion des infractions de conduite, récupération des points de permis, renouvellement du permis de conduire, suspension du permis de conduire, permis de conduire en ligne, historique des infractions de conduite, alerte de perte de points, paiement des amendes, amendes de stationnement, amendes pour excès de vitesse, gestion des contraventions, contestation d'amende, consultation des amendes, suivi des amendes, paiement en ligne des amendes, OCR des amendes, scanner des amendes, rappel entretien véhicule, gestion de l'entretien du véhicule, rappel vidange voiture, rappel contrôle technique, gestion des documents du véhicule, historique d'entretien du véhicule, suivi de l'entretien automobile, planification des entretiens de voiture, gestion de flotte de véhicules, alerte maintenance voiture, application de gestion de permis, application de gestion de véhicules, application de suivi des amendes, sécurité routière, code de la route, conseils de conduite, gestion des infractions, gestion des documents de véhicule, application mobile de gestion de permis, solution digitale pour conducteurs"
        />
      </Helmet>
      <LogoWrapper>
        <Logo src={whiteLogo} />
        <AppTitle>GérerMonPermis</AppTitle>
      </LogoWrapper>
      <Picture src={screenShot} />
      <BtnContainer>
        <BtnWrapper>
          <Img loading="lazy" src={googleplayIcon} />
          <BtnTxtWrapper
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=com.kiamtech.gerermonpermis")
            }
          >
            <BtnTxtDownload>Télécharger sur</BtnTxtDownload>
            <BtnStoreName>Google Play</BtnStoreName>
          </BtnTxtWrapper>
        </BtnWrapper>
        <BtnWrapper
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/app/g%C3%A9rermonpermis/id6502949932")
          }
        >
          <Img loading="lazy" src={appleIcon} />
          <BtnTxtWrapper>
            <BtnTxtDownload>Télécharger sur</BtnTxtDownload>
            <BtnStoreName> Apple Store</BtnStoreName>
          </BtnTxtWrapper>
        </BtnWrapper>
      </BtnContainer>
    </Wrapper>
  );
}

export default Download;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 780px) {
  }
`;

const Logo = styled.img`
  width: 120px;
  aspect-ratio: 1;
  @media (max-width: 780px) {
    width: 80px;
  }
`;

const AppTitle = styled.p`
  color: #1776e5;
  font-family: Roboto, sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin-top: 8px;
  margin-bottom: 16px;
  @media (max-width: 460px) {
    color: #1776e5;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
`;

const Picture = styled.img`
  width: 70%;
  max-width: 300px;
  margin-left: 30px;
  margin-top: 30px;
  @media (max-width: 780px) {
    /* width: 80px; */
  }
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 16px;
  color: #f7f9fa;
  white-space: nowrap;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    margin: 8px 24px;
    width: 100%;
    align-self: center;
    justify-content: center;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    margin: 8px 24px;
    width: 100%;
    align-self: center;
    flex-direction: column;
    justify-content: center;
  }
`;
const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 36px;
  align-self: start;
  margin-right: 16px;
`;

const BtnTxtWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
    flex-grow: 0;
  }
`;
const BtnTxtDownload = styled.div`
  font: 500 18px/156% Roboto, sans-serif;
  line-height: 14px;
`;
const BtnStoreName = styled.div`
  letter-spacing: -0.48px;
  font: 700 24px/133% Poppins, sans-serif;
  @media (max-width: 991px) {
  }
`;
const BtnWrapper = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #1776e5;
  display: flex;
  gap: 8px;
  padding: 16px;
  padding-bottom: 8px;
  @media (max-width: 991px) {
    width: 90%;
  }
`;
