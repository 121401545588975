import React from "react";
import styled from "styled-components";
import picture from "../assets/pictures/picBlog.webp";
import bgShape from "../assets/shapeBlog.svg";
import bgBottomShape from "../assets/bgShapeQr.svg";
import BlogSection from "../containers/BlogSection";
import qr from "../assets/qr.svg";
import FooterSection from "../containers/FooterSection";
import { articles } from "../content/Articles";
import { Helmet } from "react-helmet";

function Blog() {
  return (
    <Wrapper>
      <Helmet>
        <title>Blog - Gérer Mon Permis</title>
        <meta
          name="description"
          content="Explorez notre blog pour des articles informatifs sur le code de la route, la sensibilisation à la sécurité routière, et des conseils pratiques pour gérer vos points de permis et vos amendes."
        />
        <meta
          name="keywords"
          content="Application mobile, permis en france, Gérer Mon Permis, suivi des points de permis, vérification des points de permis, consultation des points de permis, gestion des infractions de conduite, récupération des points de permis, renouvellement du permis de conduire, suspension du permis de conduire, permis de conduire en ligne, historique des infractions de conduite, alerte de perte de points, paiement des amendes, amendes de stationnement, amendes pour excès de vitesse, gestion des contraventions, contestation d'amende, consultation des amendes, suivi des amendes, paiement en ligne des amendes, OCR des amendes, scanner des amendes, rappel entretien véhicule, gestion de l'entretien du véhicule, rappel vidange voiture, rappel contrôle technique, gestion des documents du véhicule, historique d'entretien du véhicule, suivi de l'entretien automobile, planification des entretiens de voiture, gestion de flotte de véhicules, alerte maintenance voiture, application de gestion de permis, application de gestion de véhicules, application de suivi des amendes, sécurité routière, code de la route, conseils de conduite, gestion des infractions, gestion des documents de véhicule, application mobile de gestion de permis, solution digitale pour conducteurs"
        />
      </Helmet>
      <SectionWrapper>
        <LeftContainer>
          <Badge>Blog</Badge>
          <Title>Blogs</Title>
          <Desc>
            Explorez notre blog pour des articles informatifs, des conseils
            pratiques et des astuces utiles sur la gestion des amendes, la
            sécurité routière et bien plus encore. Restez informé et découvrez
            les dernières actualités pour une conduite plus sûre et plus
            sereine.
          </Desc>
        </LeftContainer>
        <RightContainer>
          <PictureWrapper>
            <Picture src={picture} />
          </PictureWrapper>
          <BgShape src={bgShape} />
        </RightContainer>
      </SectionWrapper>
      <BlogSection articles={articles} full />
      <QrSection>
        <BgBottomShape src={bgBottomShape} />
      </QrSection>
      <FooterSection />
    </Wrapper>
  );
}

export default Blog;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const BgBottomShape = styled.img`
  width: 100%;
  bottom: 0;
`;
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  width: 90%;
  gap: 16px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
  height: 100%;
  flex-shrink: 1;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const Badge = styled.p`
  color: #ce7189;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 112px;
  background: #fce2e8;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.8px;
  margin: 0;
`;
const Desc = styled.p`
  color: #505459;
  text-align: start;
  margin: 16px, 0;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const BgShape = styled.img`
  position: absolute;
  width: 15%;
  right: 0;
  top: 20px;
  @media (max-width: 780px) {
    display: none;
  }
`;

const QrSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const PictureWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 780px) {
    width: 100%;
    flex: 0;
  }
`;

const Picture = styled.img`
  width: 100%;
`;
