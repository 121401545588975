// import React from "react";
import * as React from "react";
import styled, { keyframes } from "styled-components";
import googleplayIcon from "../assets/icons/googleplay.svg";
import appleIcon from "../assets/icons/apple.svg";
import bgShapes from "../assets/bgShape.svg";
import bgShapesMobile from "../assets/bgShapeMobile.svg";
import iphone from "../assets/pictures/screenshotHome.webp";
import iphonefine from "../assets/pictures/screenshotFine.webp";
// import stars from "../assets/icons/rateStars.svg";

function HeroSection() {
  const vibration = keyframes`
    0% { transform: translateY(-20px); }
    50% { transform: translateY(20px); }
    100% { transform: translateY(-20px); }
  `;
  const reversedVibration = keyframes`
    0% { transform: translateY(20px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(20px); }
  `;

  const ScreenShotHome = styled.img`
    display: block;
    width: 300px;
    height: 580px;
    margin-top: 80px;
    animation: ${vibration} 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    z-index: 1;
    @media (max-width: 780px) {
      height: inherit;
      width: 45%;
      aspect-ratio: 0.519/1;
    }
  `;

  const ScreenShotFine = styled.img`
    display: block;
    z-index: 2;
    width: 300px;
    height: 580px;
    margin-right: -150px;
    animation: ${reversedVibration} 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      infinite;
    @media (max-width: 780px) {
      margin-right: -25%;
      width: 45%;
      height: inherit;
      aspect-ratio: 0.519/1;
    }
  `;
  return (
    <Wrapper>
      <BgShapesMobile src={bgShapesMobile} />
      <BgShapes src={bgShapes} />
      <RightWrapper>
        <ScreenShotFine src={iphonefine} />
        <ScreenShotHome src={iphone} />
      </RightWrapper>
      <LeftWrapper>
        <Tag>GérerMonPermis</Tag>
        <BoldDesc>
          Optimisez votre temps et prenez le contrôle de votre permis avec
          GérerMonPermis.
        </BoldDesc>
        <Desc>
          Suivi détaillé des points de permis, gestion organisée des amendes,
          paiement simplifié - Votre allié pour une gestion sereine de votre
          permis.
        </Desc>
        <BtnContainer>
          <BtnWrapper>
            <Img loading="lazy" src={googleplayIcon} />
            <BtnTxtWrapper
              onClick={() =>
                (window.location.href =
                  "https://play.google.com/store/apps/details?id=com.kiamtech.gerermonpermis")
              }
            >
              <BtnTxtDownload>Télécharger sur</BtnTxtDownload>
              <BtnStoreName>Google Play</BtnStoreName>
            </BtnTxtWrapper>
          </BtnWrapper>
          <BtnWrapper
            onClick={() =>
              (window.location.href =
                "https://apps.apple.com/app/g%C3%A9rermonpermis/id6502949932")
            }
          >
            <Img loading="lazy" src={appleIcon} />
            <BtnTxtWrapper>
              <BtnTxtDownload>Télécharger sur</BtnTxtDownload>
              <BtnStoreName> Apple Store</BtnStoreName>
            </BtnTxtWrapper>
          </BtnWrapper>
        </BtnContainer>
        {/* <FooterWrapper>
          <Img3 loading="lazy" src={stars} />
          <FooterTxt>
            L'application utilisée par plus de 12.500 conducteurs
          </FooterTxt>
        </FooterWrapper> */}
      </LeftWrapper>
    </Wrapper>
  );
}

export default HeroSection;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media (max-width: 780px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;
const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: start;
  margin-right: 10%;
  margin-top: 40px;
  @media (max-width: 780px) {
    width: 100%;
    margin: 40px 0 0;
    justify-content: center;
  }
`;
const LeftWrapper = styled.div`
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 64px;
  margin-top: 180px;
  @media (max-width: 780px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 60px;
    // padding: 0px 24px;
  }
`;
const BgShapes = styled.img`
  position: absolute;
  /* width: 30vw; */
  right: 0px;
  @media (max-width: 780px) {
    display: none;
  }
`;
const BgShapesMobile = styled.img`
  position: absolute;
  width: 100%;
  right: 0px;
  top: 0;
  @media (min-width: 780px) {
    display: none;
  }
`;

const Tag = styled.text`
  justify-content: center;
  border-radius: 112px;
  background-color: #fce2e8;
  color: #ce7189;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 8px 16px;
  line-height: 8px;
  font: 600 14px/143% Roboto, sans-serif;
  @media (max-width: 780px) {
    margin: 8px 24px;
  }
`;
const BoldDesc = styled.text`
  color: #0a3567;
  text-align: start;
  letter-spacing: -1.04px;
  align-self: stretch;
  margin-top: 12px;
  font: 700 52px/56px Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 700 36px/44px Poppins, sans-serif;
  }
  @media (max-width: 780px) {
    font: 700 36px/44px Poppins, sans-serif;
    margin: 8px 24px;
  }
`;
const Desc = styled.text`
  color: #505459;
  align-self: stretch;
  margin-top: 24px;
  text-align: start;
  font: 400 18px/28px Roboto, sans-serif;
  margin: 8px 0;
  @media (max-width: 780px) {
    margin: 8px 24px;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 16px;
  color: #f7f9fa;
  white-space: nowrap;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    margin: 8px 24px;
    width: 100%;
    align-self: center;
    justify-content: center;
  }
`;
const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 36px;
  align-self: start;
`;

const BtnTxtWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
  }
`;
const BtnTxtDownload = styled.div`
  font: 500 18px/156% Roboto, sans-serif;
  line-height: 14px;
`;
const BtnStoreName = styled.div`
  letter-spacing: -0.48px;
  font: 700 24px/133% Poppins, sans-serif;
  @media (max-width: 991px) {
  }
`;
const BtnWrapper = styled.button`
  justify-content: center;
  border-radius: 16px;
  background-color: #1776e5;
  display: flex;
  gap: 8px;
  padding: 16px;
  padding-bottom: 8px;
`;
const FooterWrapper = styled.div`
  justify-content: center;
  border-radius: 16px;
  border-color: rgba(242, 133, 161, 1);
  border-style: solid;
  border-width: 2px;
  background-color: #fef3f6;
  display: flex;
  margin-top: 16px;
  gap: 16px;
  font-size: 14px;
  color: #f285a1;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  line-height: 114%;
  padding: 8px 17px;
  @media (max-width: 780px) {
    gap: 8px;
    margin: 8px 24px;
    align-self: center;
    flex-direction: column;
    align-items: center;
    //width: 100%;
  }
`;
const Img3 = styled.img`
  aspect-ratio: 5;
  object-fit: auto;
  object-position: center;
  width: 80px;
`;
const FooterTxt = styled.div`
  font-family: Roboto, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
