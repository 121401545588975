import React from "react";
import styled from "styled-components";
import picture from "../assets/pictures/aboutUsPic.png";
import appleIcon from "../assets/icons/apple.svg";
import googleplayIcon from "../assets/icons/googleplay.svg";
import FAndQSection from "../containers/FAndQSection";
import QrCodeSection from "../containers/QrCodeSection";
import FooterSection from "../containers/FooterSection";
import bgShape from "../assets/bgShapeQr.svg";
import ContactUsSection from "../containers/ContactUsSection";
import { Helmet } from "react-helmet";

function AboutUs() {
  const adventages = [
    "Des fonctionnalités complètes pour gérer tous les aspects de vos amendes.",
    "Une interface intuitive et facile à utiliser.",
    "Un système de sécurité de pointe pour protéger vos données personnelles.",
    "Un service client réactif et toujours à votre écoute.",
  ];
  return (
    <Wrapper>
      <Helmet>
        <title>À propos de l'application Gérer Mon Permis</title>
        <meta
          name="description"
          content="Apprenez-en plus sur Gérer Mon Permis, l'application innovante pour consulter vos points de permis, gérer amendes, et programer des rappels pour vos véhicules. Découvrez notre mission et notre équipe."
        />
        <meta
          name="keywords"
          content="Application mobile, permis en france, Gérer Mon Permis, suivi des points de permis, vérification des points de permis, consultation des points de permis, gestion des infractions de conduite, récupération des points de permis, renouvellement du permis de conduire, suspension du permis de conduire, permis de conduire en ligne, historique des infractions de conduite, alerte de perte de points, paiement des amendes, amendes de stationnement, amendes pour excès de vitesse, gestion des contraventions, contestation d'amende, consultation des amendes, suivi des amendes, paiement en ligne des amendes, OCR des amendes, scanner des amendes, rappel entretien véhicule, gestion de l'entretien du véhicule, rappel vidange voiture, rappel contrôle technique, gestion des documents du véhicule, historique d'entretien du véhicule, suivi de l'entretien automobile, planification des entretiens de voiture, gestion de flotte de véhicules, alerte maintenance voiture, application de gestion de permis, application de gestion de véhicules, application de suivi des amendes, sécurité routière, code de la route, conseils de conduite, gestion des infractions, gestion des documents de véhicule, application mobile de gestion de permis, solution digitale pour conducteurs"
        />
      </Helmet>
      <SectionWrapper>
        <LeftContainer>
          <Title>Une solution conçue pour vous simplifier la vie</Title>
          <Desc>Votre compagnon de route pour des amendes sans surprises</Desc>
          <Desc>
            Chez GérerMonPermis, nous savons qu'une amende peut être une source
            de stress et de tracas. C'est pourquoi nous avons créé une
            application simple et efficace qui vous aide à gérer vos amendes en
            toute sérénité.
          </Desc>
          <Desc>
            Notre mission est simple : vous simplifier la vie en vous permettant
            de :
          </Desc>
          <PointTxt>
            • Visualiser facilement votre solde de points de permis.
          </PointTxt>
          <PointTxt>
            • Organiser et suivre vos amendes en un seul endroit.
          </PointTxt>
          <PointTxt>
            • Payer vos amendes en toute sécurité directement depuis
            l'application.
          </PointTxt>
          <PointTxt>
            • Contester des amendes injustifiées en toute confiance.
          </PointTxt>
          <PointTxt>
            • Rester informé des dernières actualités du code de la route.
          </PointTxt>
        </LeftContainer>
        <RightContainer>
          <Picture src={picture} />
        </RightContainer>
      </SectionWrapper>
      <AdvantageWrapper>
        <Desc>
          Nous sommes une équipe passionnée par la technologie et la sécurité
          routière. Nous avons développé GérerMonPermis en pensant aux
          conducteurs comme vous, qui veulent pouvoir se concentrer sur la route
          sans se soucier des amendes.
        </Desc>
        <Desc>Voici ce qui nous différencie :</Desc>
        <Advantages>
          {adventages.map((advantage, index) => (
            <Advantage key={index}>{advantage}</Advantage>
          ))}
        </Advantages>
      </AdvantageWrapper>
      <FAndQSection />
      <ContactUsSection />
      <QrCodeSection bgShape={bgShape} />
      <FooterSection />
    </Wrapper>
  );
}

export default AboutUs;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  width: 90%;
  gap: 16px;
  @media (max-width: 1320px) {
    align-items: start;
  }
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const AdvantageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
  width: 90%;
  gap: 16px;
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: stretch;
  align-items: stretch;
  width: 50%;
  height: 100%;
  flex-shrink: 1;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.8px;
  text-align: start;
  margin: 0;
  margin-bottom: 16px;
`;
const Desc = styled.p`
  color: #505459;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
  margin: 8px 0;
`;
const PointTxt = styled.p`
  color: #0a3567;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
  margin: 0;
`;

const Picture = styled.img`
  width: 100%;
  //height: 100%;
`;

const BtnContainer = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 16px;
  color: #f7f9fa;
  white-space: nowrap;
  margin-top: 24px;
  @media (max-width: 970px) {
    //flex-direction: column;

    width: 100%;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }
`;
const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 36px;
  align-self: start;
`;

const BtnTxtWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
  }
`;
const BtnTxtDownload = styled.div`
  font: 500 18px/156% Roboto, sans-serif;
  line-height: 14px;
  color: #f7f9fa;
`;
const BtnStoreName = styled.div`
  letter-spacing: -0.48px;
  font: 700 24px/133% Poppins, sans-serif;
  color: #f7f9fa;
  @media (max-width: 991px) {
  }
`;
const BtnWrapper = styled.div`
  justify-content: flex-end;
  border-radius: 16px;
  background-color: #1776e5;
  display: flex;
  gap: 8px;
  padding: 16px;
  padding-bottom: 8px;
  @media (max-width: 495px) {
    width: 100%;
  }
`;
const Advantages = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  @media (max-width: 850px) {
    flex-direction: column;
    padding: 0;
    gap: 20px;
  }
`;

const Advantage = styled.p`
  color: #f7f9fa;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
  border-radius: 32px;
  background: #418fea;
  padding: 16px 32px;
  flex: 1;
  text-align: start;
  @media (max-width: 850px) {
    margin: 0;
    width: auto;
    padding: 32px 16px;
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
`;

const ContactTitle = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.56px;
  text-align: start;
`;

const ContactIcon = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 168px;
  background: #0a3567;
  //width: 60px;
  aspect-ratio: 1;
`;
const Divider = styled.img`
  width: 100%;
`;

const ContactWrapper = styled.div`
  display: flex;
  margin: 16px 0;
  align-items: center;
`;
const ContactTxtWrapper = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 30px;
`;

const ContactTypeTxt = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin: 0;
  text-align: start;
`;
const ContactTxt = styled.p`
  color: #1776e5;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  text-align: start;
`;

const SocialMedia = styled.div`
  margin-top: 24px;
`;

const Button = styled.button`
  border-radius: 16px;
  background: #1776e5;
  display: flex;
  height: 48px;

  width: auto;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #f7f9fa;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ErrorTxt = styled.p`
  color: red;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 10px;
  margin: 0 16px;
  text-align: start;
`;
