import React from "react";
import styled from "styled-components";
import userProfile from "../assets/user-placeholder.svg";
import shape50 from "../assets/shape50.svg";
import { reviews } from "../content/reviews";

function ReviewsSection() {
  return (
    <Wrapper id="reviews">
      <ContentWrapper>
        <Title>Découvrez ce que disent nos utilisateurs</Title>
        <Desc>
          Découvrez par vous-même comment GérerMonPermis a transformé la gestion
          des amendes pour des conducteurs comme vous !
        </Desc>
        <Content>
          {reviews?.map((review, index) => (
            <>
              <ReviewWrapper>
                <ReviewTxt>{review.comment}</ReviewTxt>
                <UserWrapper>
                  <ProfileImage>
                    <img
                      src={userProfile}
                      alt="userProfileImg"
                      width={"100%"}
                    />
                  </ProfileImage>
                  <UserInfo>
                    <UserName>{review.userName}</UserName>
                    <UserRole>{review.role}</UserRole>
                  </UserInfo>
                </UserWrapper>
                {/* <Overlay /> */}
              </ReviewWrapper>
            </>
          ))}
        </Content>
      </ContentWrapper>
      <BgShape src={shape50} />
    </Wrapper>
  );
}

export default ReviewsSection;
const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background: linear-gradient(90deg, #c7def9 0%, #e8f1fc 78.26%);
  align-items: center;
  padding-top: 64px;
  justify-content: space-between;
  @media (max-width: 780px) {
    /* flex-direction: column;
    padding: 24px; */
  }
`;
const ContentWrapper = styled.div`
  position: relative;
  margin: 80px 10%;
  display: flex;
  width: 100%;
  z-index: 1;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 780px) {
    margin: 32px;
    flex-direction: column;
    padding: 24px;
  }
`;

const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0;
  @media (max-width: 780px) {
    color: #0a3567;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.56px;
  }
`;
const Desc = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  margin-top: 8px;
  text-align: start;
`;

const ReviewWrapper = styled.div`
  display: flex;
  width: 25%;
  position: relative;
  min-width: 250px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #9bc4f4;
  background: #e8f1fc;
  flex-grow: 1;
  justify-content: space-between;
  @media (max-width: 731px) {
    width: 100%;
    min-width: 100px;
  }
`;
const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  @media (max-width: 731px) {
    justify-content: center;
  }
`;
const ProfileImage = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 10%;
  border-radius: 50%;
  /* background-color: #eaeff7; */
  padding: 4px;
  border: 2px solid #9bc4f4;
  @media (max-width: 731px) {
  }
`;

const ReviewTxt = styled.p`
  text-align: start;
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 0;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 8px;
`;
const UserName = styled.p`
  color: #1776e5;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
`;
const UserRole = styled.p`
  margin: 0;
  color: #418fea;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

const BgShape = styled.img`
  // width: 12%;
  position: absolute;
  right: 0;
  top: 170px;
  @media (max-width: 780px) {
    display: none;
  }
`;
const Overlay = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 32px;
  box-sizing: border-box;
  background: rgba(209, 224, 248, 0.27);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(12.5px);
  border: 1px solid rgba(209, 224, 248, 0.55);
  @media (max-width: 780px) {
    /* display: none; */
  }
`;
