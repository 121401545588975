import React from "react";
import styled from "styled-components";
import blogImg from "../assets/pictures/BlogImg.png";
import shape50 from "../assets/shape50.svg";
import { useNavigate, NavLink } from "react-router-dom";

function BlogSection({ full, articles, style }) {
  const navigate = useNavigate();
  const renderedArticles = full ? articles : articles.slice(0, 4);
  const handleSelectArticle = (article) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate(`/blog/${article.id}`);
  };
  return (
    <Wrapper style={{ paddingTop: full ? "64px" : 0 }} id="blog">
      {full && <BgShape src={shape50} />}
      <ContentWrapper style={{ ...style }}>
        {full && (
          <>
            <Title>Blogs</Title>
            <Desc>
              Explorez notre blog pour des articles informatifs, des conseils
              pratiques et des astuces utiles sur la gestion des amendes, la
              sécurité routière et bien plus encore. Restez informé et découvrez
              les dernières actualités pour une conduite plus sûre et plus
              sereine.
            </Desc>
          </>
        )}
        <Content>
          {renderedArticles.map((article, index) => (
            <ArticleWrapper key={index}>
              <ArticleImg
                src={require(`../assets/articles/article${article.id}.webp`)}
                onClick={() => handleSelectArticle(article)}
              />
              <ArticleTitle onClick={() => handleSelectArticle(article)}>
                {article.title}
              </ArticleTitle>
              <ArticleDesc>{article.desc}</ArticleDesc>
            </ArticleWrapper>
          ))}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}

export default BlogSection;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 780px) {
  }
`;
const ContentWrapper = styled.div`
  position: relative;
  margin: 80px 5%;
  display: flex;
  width: 100%;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 800px) {
    justify-content: start;
  }
`;

const Title = styled.p`
  color: #0a3567;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0;
  text-align: start;
`;
const Desc = styled.p`
  color: #222426;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  text-align: start;
  margin-top: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  @media (max-width: 800px) {
    justify-content: start;
  }
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 48%;
  margin-top: 16px;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const ArticleImg = styled.img`
  width: 100%;
  aspect-ratio: 2;
  border-radius: 32px;
  cursor: pointer;
  @media (max-width: 780px) {
    border-radius: 24px;
  }
`;
const ArticleTitle = styled.a`
  color: #0a3567;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: start;
  margin: 0;
  cursor: pointer;
`;
const ArticleDesc = styled.p`
  color: #505459;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
  margin: 0;
`;

const BgShape = styled.img`
  position: absolute;
  left: 0;
  top: 220px;
  transform: scaleX(-1);
  @media (max-width: 780px) {
    display: none;
  }
`;
