import React, { useEffect } from "react";
import HeroSection from "../containers/HeroSection";
import HomeSection2 from "../containers/HomeSection2";
import FeaturesSection from "../containers/FeaturesSection";
import QrCodeSection from "../containers/QrCodeSection";
import ReviewsSection from "../containers/ReviewsSection";
import HowToUseSection from "../containers/HowToUseSection";
import BlogSection from "../containers/BlogSection";
import FAndQSection from "../containers/FAndQSection";
import FooterSection from "../containers/FooterSection";
import bgShape from "../assets/bgShapeQrSection.svg";
import { articles } from "../content/Articles";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

function Home() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const featureId = queryParams.get("featureId");
  const sectionId = queryParams.get("sectionId");
  function scrollToSection(id) {
    let section;
    if (featureId !== null) {
      section = document.getElementById(`feature-${id}`);
    } else if (sectionId !== null) {
      section = document.getElementById(`${id}`);
    }
    section.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (featureId) {
      scrollToSection(featureId);
    }
    if (sectionId) {
      scrollToSection(sectionId);
    }
  }, [featureId, sectionId]);
  return (
    <Wrapper>
      <Helmet>
        <title>
          Gérer Mon Permis - Optimisez la Gestion de Votre Permis de Conduire
        </title>
        <meta
          name="description"
          content="Découvrez Gérer Mon Permis, l'application mobile qui vous permet de vérifier vos points de permis, ajouter et payer vos amendes, et gérer vos véhicules avec des rappels pour ne pas rater la vidange ou contrôle technique par exemple."
        />
        <meta
          name="keywords"
          content="Application mobile, permis en France, Gérer Mon Permis, suivi des points de permis, vérification des points de permis, consultation des points de permis, gestion des infractions de conduite, récupération des points de permis, renouvellement du permis de conduire, suspension du permis de conduire, permis de conduire en ligne, historique des infractions de conduite, alerte de perte de points, paiement des amendes, amendes de stationnement, amendes pour excès de vitesse, gestion des contraventions, contestation d'amende, consultation des amendes, suivi des amendes, paiement en ligne des amendes, OCR des amendes, scanner des amendes, rappel entretien véhicule, gestion de l'entretien du véhicule, rappel vidange voiture, rappel contrôle technique, gestion des documents du véhicule, historique d'entretien du véhicule, suivi de l'entretien automobile, planification des entretiens de voiture, gestion de flotte de véhicules, alerte maintenance voiture, application de gestion de permis, application de gestion de véhicules, application de suivi des amendes, sécurité routière, code de la route, conseils de conduite, gestion des infractions, gestion des documents de véhicule, application mobile de gestion de permis, solution digitale pour conducteurs, constat amiable, constat d'accident, déclaration d'accident, gestion des accidents routiers, prévention des accidents, responsabilité civile, indemnisation après accident, procédure de constat amiable, formulaire de constat, erreurs à éviter lors d'un constat, guide du conducteur après un accident, application pour remplir un constat amiable, outils numériques pour constats d'accident"
        />
      </Helmet>
      <HeroSection />
      <HomeSection2 />
      <FeaturesSection />
      <QrCodeSection bgShape={bgShape} />
      <ReviewsSection />
      <HowToUseSection />
      <BlogSection articles={articles} />
      <FAndQSection id="F&Q" />
      <FooterSection />
    </Wrapper>
  );
}

export default Home;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
