import axios from 'axios';

axios.interceptors.request.use(
  function (request) {
    // Affichez la requête dans la console pour le débogage
    console.log('Starting Request', request);
    return request;
  },
  function (error) {
    // Gérez les erreurs de requête ici
    console.error('Request error', error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Affichez la réponse dans la console pour le débogage
    console.log('Response:', response);
    return response;
  },
  function (error) {
    // Gérez les erreurs de réponse ici
    console.error('Response error', error);
    return Promise.reject(error);
  }
);

// Vous pouvez exporter la configuration axios si nécessaire
export default axios;
