import * as yup from "yup";

const emailRegex =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|fr|kr|net|us|info|biz|me|name|cc|org|tv|ws|mobi|de|am|fm|ca|bz|com.bz|net.bz|es|co|se|la)$/;

const emailSchema = yup.object().shape({
  name: yup.string().required("Ce champ est requis"),
  email: yup
    .string()
    .email("Veuillez entrer une adresse e-mail valide")
    .required("Ce champ est requis")
    .matches(emailRegex, "Veuillez entrer une adresse e-mail valide"),
  massage: yup.string().required("Ce champ est requis"),
});

export default emailSchema;
