import React from "react";
import styled from "styled-components";
import qrSectionPic from "../assets/pictures/qr.webp";
import qr from "../assets/qr.svg";

function QrCodeSection({ bgShape }) {
  return (
    <Wrapper>
      <BgShapeQr src={bgShape} />
      <LeftContainerWrapper>
        <QrTitle>
          Téléchargez GérerMonPermis dès maintenant et débarrassez-vous du
          stress lié aux amendes !
        </QrTitle>
        <QrWrapper>
          <Img src={qr} />
          <DownloadTxtWrapper>
            <DownloadTitle>Scannez pour télécharger gratuitement</DownloadTitle>
            <DownloadDesc>Disponible sur iOS et Android</DownloadDesc>
          </DownloadTxtWrapper>
        </QrWrapper>
      </LeftContainerWrapper>
      <ImageWrapper>
        <Image src={qrSectionPic} alt="Téléchargez Gérer Mon Permis" />
      </ImageWrapper>
    </Wrapper>
  );
}

export default QrCodeSection;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 64px;
  justify-content: space-between;
  @media (max-width: 780px) {
    flex-direction: column;
    padding: 24px 0;
  }
`;
const Image = styled.img`
  width: 90%;
  margin-bottom: -150px;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const BgShapeQr = styled.img`
  width: 100%;
  bottom: 0;
  position: absolute;
`;
const ImageWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: flex-end;
`;
const LeftContainerWrapper = styled.div`
  margin-top: 60px;
  z-index: 1;
  display: flex;
  width: 40%;
  flex-direction: column;
  padding: 64px;
  @media (max-width: 780px) {
    width: 90%;
    padding: 24px 0;
  }
`;
const QrTitle = styled.p`
  text-align: start;
  color: #0a3567;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.56px;
  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
  }
`;

const Img = styled.img`
  width: 40%;
  border-radius: 12px;
`;

const QrWrapper = styled.div`
  display: flex;
  align-self: self-start;
  width: 450px;
  padding: 32px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #1776e5;

  z-index: 1;
  @media (max-width: 780px) {
    flex-direction: column;
    width: auto;
    align-self: stretch;
  }
`;
const DownloadTxtWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const DownloadTitle = styled.p`
  color: #f7f9fa;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: start;
  margin: 0;

  @media (max-width: 780px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
    text-align: center;
  }
`;
const DownloadDesc = styled.p`
  color: #f7f9fa;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: start;
  margin: 0;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;
